import { useEffect, useRef, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Logo from '@assets/images/kohler_logo.svg';
import { getSearchResults } from '@services/ProductAPI/client';

const NewSearch = ({
  setShowSearchModal,
  secondaryNavHeaderData,
  setShowSearch,
  isHeroWithHeading
}) => {
  const router = useRouter();
  const [query, setQuery] = useState('');
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [searchOpen] = useState(false);
  useEffect(() => {
    return () => setQuery('');
  }, []);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this; // Store the execution context
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay); // Use apply to pass the correct arguments
    };
  };

  const fetchSuggestions = async value => {
    const data = await getSearchResults({ search: value });
    setSuggestions(data?.suggestions);
  };
  const searchCallback = debounce(fetchSuggestions, 3000);

  const startSearch = e => {
    const { value } = e.target;
    setQuery(value);
    // Commenting until we have suggestion endpoint from ACS team
    setShowSuggestion(true);
    if (value.length > 2) {
      searchCallback(value);
    } else {
      setShowSuggestion(false);
    }
  };

  const redirectToPDP = ({ ProductProductNo, ProductLocalCategory }) => {
    setShowSuggestion(false);
    setShowSearchModal(false);
    setShowSearch(false);
    setQuery('');

    router.push(
      `/products/${ProductLocalCategory.toLowerCase()}/${ProductProductNo.toLowerCase()}`
    );
  };

  const clickSearch = () => {
    if (query !== '') {
      router.push(
        `${process.env.API_BASEURL}/site-search?q=${query.toLowerCase()}`
      );
      setQuery('');
      setShowSearchModal(false);
      setShowSearch(false);
    }
  };
  const navClasses = cx(
    'flex-none',
    'lg:flex',
    'items-center',
    'justify-between',
    'lg:px-10',
    'lg:pl-12',
    'px-4',
    'py-3',
    'p-0',
    {
      'text-black bg-transparent lg:text-white lg:mt-0 lg:hover:bg-white lg:hover:text-black':
        isHeroWithHeading
    },
    {
      'bg-white top-0': searchOpen && overlay
    },
    {
      'lg:mt-0 bg-white': !isHeroWithHeading
    },
    'absolute',
    'z-[1]',
    'group',
    'w-full'
  );
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div className={navClasses}>
      <div className='flex-none lg:flex w-full lg:w-auto border-0'>
        <div className=''>
          <Link legacyBehavior href={'/'}>
            <a className='flex-nowrap mr-4 absolute pl-6 ml-[56px] lg:relative order-2 no-underline flex lg:mr-2 lg:pl-0 lg:ml-[0px]'>
              <div className='flex items-center logoResponsive md:pt-2 pt-3 pb-2'>
                <Image
                  height='32'
                  width='96'
                  alt='Kohler'
                  className={cx(
                    'h-auto sm:h-10 mr-2 outline-none',
                    {
                      'lg:group-hover:invert-0 invert': isHeroWithHeading
                    },
                    {
                      'invert-0': searchOpen && overlay
                    }
                  )}
                  src={Logo}
                />
                <div className='border-l-[3px] pl-2 border-l-primary border-solid h-[22px]'></div>
                <div
                  className={cx(
                    'mt-0 align-middle lg:mr-3 ',
                    {
                      'text-white lg:group-hover:text-black': isHeroWithHeading
                    },
                    {
                      'text-black':
                        !isHeroWithHeading || (searchOpen && overlay)
                    }
                  )}
                >
                  <span className='font-helNowLight text-[13px] font-bold border-0 sm:text-[22px] '>
                    Home Energy
                  </span>
                </div>
              </div>
            </a>
          </Link>
        </div>
        <div className='flex pt-[13px] pl-[13px] xl:pl-[220px]  relative'>
          <span
            className='text-[20px] text-gray-500 absolute'
            onClick={() => clickSearch()}
            onKeyDown={e => {
              if (e.key === 'Enter') clickSearch();
            }}
          >
            <HiSearch size={25} />
          </span>
          <input
            type='text'
            ref={inputRef}
            aria-label='Search'
            className='text-2xl he-header-search-input-box focus:outline-none'
            placeholder='Search'
            onChange={e => startSearch(e)}
            onKeyDown={e => {
              if (e.key === 'Enter') clickSearch();
            }}
            value={query}
          />
          {query !== '' && (
            <span
              className='bg-transparent text-black h-9 w-9 text-2xl block cursor-pointer'
              onClick={() => setQuery('')}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  setQuery('');
                }
              }}
            >
              <XMarkIcon className='align-middle h-9 text-white-500 stroke-slate-50' />
            </span>
          )}
          <span
            className='font-bold pt-[9px] md:pl-[40px] xl:pl-[75px] cursor-pointer hidden md:block'
            onClick={() => {
              setShowSearch(false);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                setShowSearch(false);
              }
            }}
          >
            Cancel
          </span>
        </div>
        <div
          className='font-bold p-[10px] cursor-pointer block md:hidden'
          onClick={() => {
            setShowSearch(false);
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setShowSearch(false);
            }
          }}
        >
          Cancel
        </div>
      </div>
      {suggestions?.length > 0 && showSuggestion && (
        <ul
          //   onMouseLeave={() => setShowSuggestion(false)}
          className='absolute left-[-26px] top-14 z-10 border border-gray-300 w-full bg-white'
        >
          {suggestions?.map(i => {
            return (
              <li
                key={i.ProductProductNo}
                onClick={() => redirectToPDP(i)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    redirectToPDP(i);
                  }
                }}
                className='py-2 ml-[20px] lg:ml-[380px] list-none cursor-pointer hover:bg-gray-100'
              >
                {i?.suggestion}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default NewSearch;
