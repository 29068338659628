import { useCallback, useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { useRouter } from 'next/router';

const SearchMobile = ({ setShowSearchModal }) => {
  const router = useRouter();
  const [query, setQuery] = useState('');
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [suggestions] = useState([]);
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const startSearch = debounce(async e => {
    setShowSuggestion(true);
    const { value } = e.target;
    setQuery(value);
  }, 300);

  const redirectToPDP = productNo => {
    setShowSuggestion(false);
    setShowSearchModal(false);
    setQuery('');

    router.push(`/products/${productNo}`);
  };

  const clickSearch = () => {
    router.push(`/site-search?q=${query}`);
    setQuery('');
    setShowSearchModal(false);
  };
  return (
    <div className='relative flex flex-col mr-3 my-2'>
      <input
        // commented until we have suggestion endpoint from ACS team
        // onChange={e => startSearch(e)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            clickSearch();
          }
        }}
        style={{ outline: 'none', border: 'none' }}
        className='pl-10 pr-4 py-2 text-xl'
        placeholder='Search'
        type='text'
        name='search'
      />
      <div className='border-b-[1px] border-solid border-black'></div>
      <div
        onClick={() => clickSearch()}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            clickSearch();
          }
        }}
        className='absolute inset-y-0 left-0 pl-3 flex items-center'
      >
        <HiSearch />
      </div>
      {suggestions?.length > 0 && showSuggestion && (
        <ul
          onMouseLeave={() => setShowSuggestion(false)}
          className='absolute left-[-26px] top-8 z-10 border border-gray-300 w-64 bg-white'
        >
          {suggestions?.map((i, ProductProductNo) => (
            <li
              key={i.ProductProductNo}
              onClick={() => redirectToPDP(i?.ProductProductNo)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  redirectToPDP(i?.ProductProductNo);
                }
              }}
              className='py-2 list-none cursor-pointer hover:bg-gray-100'
            >
              {i?.suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchMobile;
