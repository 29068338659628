export const defaultLocale = 'en-US';

export const UnknownLocale = Symbol('unknown-locale');

// need to be in sync with space
export const availableLocales = [defaultLocale, 'de-DE'];

export const FIRST = 0;
export const THRESHOLD = 1;

export const HOME = 'home-page';

export const PageContentTypes = {
  HelpDeskArticle: 'page_help_center_article',
  LandingPage: 'homeEnergyLandingPage',
  Sections: 'sections',
  HeadingwithSubText: 'headingWithSubText',
  HeroSection: 'heroSectionV2',
  CardRows: 'portraitIconCard',
  TextContentSection: 'textContentSection',
  SideBySide: 'sideBySideContent',
  InformationModuleSection: 'informationModuleSection',
  Carousel: 'carousel',
  Form: 'form',
  Mediaitem: 'mediaItem',
  AccordionContent: 'accordion',
  VideoGalleryId: 'videoGallery'
};

export const HeroSectionTypes = {
  ShortHeroBanner: 'ShortHeroBanner',
  HomePageHeroBanner: 'HomePageHeroBanner'
};

export const sectionBackgroundColorOptions = {
  Black: {
    bgcolor: 'black',
    textcolor: 'white',
    bordercolor: 'primary'
  },
  White: {
    bgcolor: 'white',
    textcolor: 'black',
    bordercolor: 'primary'
  },
  'Light Gray': {
    bgcolor: 'gray',
    textcolor: 'black',
    bordercolor: 'primary'
  },
  'Kohler Energy Blue': {
    bgcolor: 'primary',
    textcolor: 'white',
    bordercolor: 'white'
  }
};

export const SIDEBYSIDEVARIANTS = {
  THIRTYSEVENTY: '30/70 Content',
  SEVENTYTHIRTY: '70/30 Content',
  FIFTYFIFTY: '50/50 Content'
};

export const HORIZONTAL = 'Horizontal';
export const LEFT = 'left';
export const RIGHT = 'right';
export const DefaultFormSuccessMessageHeading =
  'Form has been successfully submitted';

export const headingTagMap = {
  H1: {
    component: 'h1',
    componentStyles: 'xl:text-[70px] tracking-[-0.8px] text-4xl lg:text-5xl',
    subTextStyles:
      'xl:text-[32px] !leading-10 lg:text-2xl text-xl leading-5 max-[1024px]:!leading-8'
  },
  H2: {
    component: 'h2',
    componentStyles:
      'max-sm:text-3xl xl:text-[54px] tracking-[-0.8px] xl:!leading-[60px] leading text-5xl lg:text-6xl',
    subTextStyles: 'text-2xl lg:leading-10'
  },
  H3: {
    component: 'h3',
    componentStyles:
      'tracking-[-0.8px] xl:!leading-[60px] text-4xl lg:text-5xl',
    subTextStyles: 'text-base lg:leading-5'
  },
  H4: {
    component: 'h4',
    componentStyles: '',
    subTextStyles: ''
  },
  H5: { component: 'h5' },
  H6: { component: 'h6' }
};

export const messages = {
  SUBSCRIBED:
    'Thank you, {email} is now subscribed to receive Kohler Home Energy emails.',
  EMAIL_SUBSCRIBED_ERROR:
    'There is an error in subscribing the email. Please try again later.'
};

export const RowVariant = {
  TWOFORMITEMS: '50/50 of 2 Form Items',
  FULLWIDTH: 'Full width Form Item'
};

export const FormItemTypes = {
  Inputtext: 'formItemInputText',
  Checkboxitem: 'formItemCheckbox'
};

export const navigationDataFilter = {
  header: 'header',
  footer: 'footer'
};
const US_ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const CANADA_ZIP_REGEX =
  /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$|^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
export const validationRules = {
  emailRegex: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
  nameRegex: /^[a-zA-Z]+/,
  numberRegex: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  zipRegex: new RegExp(
    '(' + US_ZIP_REGEX.source + ')|(' + CANADA_ZIP_REGEX.source + ')'
  ),
  zipPostalRegex:
    /^(?:\d{5}(?:-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i
};
export const Variants = [
  {
    value: 'Centered 1(width) X 2(count) of 2 Cards',
    type: 'centeredRows',
    cards: 2
  }
];

export const BreadcrumbPaths = [' ', 'homepage', 'find-a-dealer'];
