export const openInNewTab = url => url && window.open(url, '_blank');

/**
 * Retrieves alignment classes by textColor param.
 * @method
 * @param {Text} alignment - Alignment type
 * @returns {Text} Returns alignment classes
 */
export const alignmentClasses = alignment => {
  {
    switch (alignment) {
      case 'Left':
        return 'text-left justify-start';
      case 'Right':
        return 'text-right justify-end';
      case 'Center':
        return 'text-center justify-center';
      default:
        return '';
    }
  }
};
/**
 * Retrieves text classes by textColor param.
 * @method
 * @param {Text} textColor - Text color
 * @returns {Text} Returns Text color classes
 */
export const getTextColorClasses = textColor => {
  switch (textColor) {
    case 'Black':
      return 'text-black';
    case 'White':
      return 'text-white';
    case 'Energy Blue':
      return 'text-primary';
    case 'Kohler Blue':
      return 'text-primary';
    case 'Blue':
      return 'text-primary';
    case 'Transparent':
      return 'bg-transparent';

    default:
      return null;
  }
};
/**
 * Retrieves Background classes by backgroundColor param.
 * @method
 * @param {Text} backgroundColor - Background color
 * @returns {Text} Returns Background color classes
 */
export const getBgColorClasses = backgroundColor => {
  switch (backgroundColor) {
    case 'Black':
      return 'bg-black';
    case 'White':
      return 'bg-white';
    case 'Energy Blue':
      return 'bg-primary';
    case 'Kohler Blue':
      return 'bg-primary';
    case 'Blue':
      return 'bg-primary';
    case 'Light Gray':
      return 'bg-[#F0F0F5]';
    case 'Transparent':
      return 'bg-transparent';
    case 'Dark Gray':
      return 'bg-[#505055]';
    case 'Gray':
      return 'bg-[#A0A0A5]';
    case 'Medium Gray':
      return 'bg-[#C8C8CD]';
    default:
      return null;
  }
};

export const createPDPSeoData = (productData = {}) => {
  return {
    title: `${productData?.ProductProductNo} | ${productData?.ProductSeries?.en} | KOHLER`,
    ItemNo: productData?.ProductProductNo,
    ContentDescription: productData?.ProductDescriptionProduct,
    ContentImage: productData?.images?.[0]?.ResourceName,
    BoreIn: productData?.specs?.bore?.in,
    BoreMm: productData?.specs?.bore?.mm,
    EngineLengthIn: convertMmToInch(productData?.ProductOverallLengthMm),
    EngineWidthIn: convertMmToInch(productData?.ProductOverallWidthMm),
    EngineHeightIn: convertMmToInch(productData?.ProductOverallHeightMm),
    DisplacementCuIn: productData?.specs?.displacement?.cuin,
    DisplacementCc: productData?.specs?.displacement?.cc,
    DryWeightLbs: productData?.specs?.dryWeight?.lbs,
    DryWeightKg: productData?.specs?.dryWeight?.kg,
    EngineType: productData?.specs?.engineType,
    EngineCategory: productData?.specs?.engineTypeCategory,
    PeakTorqueAtMaxLbsFt: productData?.specs?.torque?.lbsft,
    PeakTorqueAtMaxNm: productData?.specs?.torque?.nm,
    MaxPowerJ1940Hp: productData?.specs?.power?.hp,
    MaxPowerJ1940Kw: productData?.specs?.power?.kw,
    StrokeIn: productData?.specs?.stroke?.in,
    StrokeMm: productData?.specs?.stroke?.mm
  };
};

function convertMmToInch(item) {
  if (item) {
    return (parseInt(item) / 25.4).toFixed(2);
  }
  return null;
}

export const getHeroSectionSize = size => {
  const heroSectionSizes = {
    Small: 'h-[250px] lg:h-[280px]',
    Medium: 'h-[250px] lg:h-[520px]',
    Large: 'h-[250px] lg:h-[calc(100vh-35px)]'
  };
  return heroSectionSizes[size] ?? '';
};
export const isEnterOrSpaceKey = e => {
  if (e.key === 'Enter' || e.key === ' ') {
    e.preventDefault();
    return true;
  }
  return false;
};
export const getOpenerType = openerType => {
  if (openerType == 'New Tab') {
    return '_blank';
  }
  return '';
};
